import React, {createContext, useState, useContext, useEffect, ReactNode} from 'react';
import {useMutation} from "@tanstack/react-query";
import {request} from '../../../Infrastructure';
import {
    getProductAttributes,
    getAutoRelatedSkus,
    getAutoRelatedProducts,
    productReviewRatingsMetadata,
    createProductReview,
    getProductReviews,
} from '../../queries';
import {useQuery} from "@tanstack/react-query";

interface ProductAttributes {
    id;
    sku;
    name;
    type_id: string;
    description?: {
        html: string;
    };
    short_description?: {
        html
    };
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    categories?: {
        id: number;
        name: string;
        url_path: string;
    }[];
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
    special_text_promo?: string;
    text_from_date?: string;
    text_to_date?: string;
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    price_range?: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
        discount?: {
            amount_off: { value: number; currency: string };
            percent_off: { value: number; currency: string }
        }
    };
}

interface AutoRelatedSkus {
    id: string;
    sku: string;
    name: string;
}

interface AutoRelatedProducts {
    id;
    sku;
    name;
    type_id: string;
    small_image?: { label: string; };
    customProductImageUrl?: { url: string; };
    price_range: {
        minimum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string };
            discount: { amount_off: number; percent_off: number }
        };
        maximum_price?: {
            regular_price: { value: number; currency: string };
            final_price: { value: number; currency: string }
        }
    };
    url_rewrites: {
        url: string;
        parameters?: {
            name: string;
            value: string;
        }[];
    }[];
    custom_attributes?: {
        attribute_code: string;
        value: string;
    }[];
}

//reviews

type configType = {}

interface CreateProductReviewRequest {
    nickname: string;
    summary: string;
    text: string;
    ratings: {
        rating_name: string;
        rating_value: string;
    };
}

interface Review {
    summary: string;
    text: string;
    nickname: string;
    created_at: string;
    average_rating: number;
    ratings_breakdown: {
        name: string;
        value: string;
    }[];
}


type ProductViewStateType = {
    productSku: string;
    productAttributes: ProductAttributes | null;
    description: string;
    customAttributes: {
        attribute_code: string;
        value: string;
    }[] | null;
    autoRelatedProducts: any[];
    loading: boolean;
    error: any;
    handleAddReview: (review: any) => void;
    config: any;
    reviewRatings: any[];
    neededRating: any;
    productReviews: Review[];
};

type ProductViewContextType = ProductViewStateType & {}

const ProductViewStateContext = createContext<ProductViewContextType | undefined>(undefined);

export const ProductViewProvider: React.FC<{
    children: React.ReactNode,
    productSku: string,
    config: configType
}> = (
    {
        children,
        productSku,
        config
    }) => {

    const [productAttributes, setProductAttributes] = useState<ProductAttributes | null>(null);
    const [autoRelatedSkus, setAutoRelatedSkus] = useState<AutoRelatedSkus[] | []>([]);
    const [autoRelatedProducts, setAutoRelatedProducts] = useState<AutoRelatedProducts[] | []>([]);
    const customAttributes = productAttributes?.custom_attributes || null;
    const description = productAttributes?.description.html || null;

    const qualityRating = 'Calitate';
    const PAGE_SIZE = 50;
    const currentReviewPage = 1;

    const [reviewRatings, setReviewRatings] = useState<any[]>([]);
    const [productReviews, setProductReviews] = useState<Review[]>([]);
    const neededRating = reviewRatings.find(rating => rating.name === qualityRating);

    const productAttributesQuery = useQuery({
        queryKey: ['productAttributes'],
        queryFn: async () => {
            return request(getProductAttributes, {sku: productSku});
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const autoRelatedSkusQuery = useQuery({
        queryKey: ['autoRelatedSkus', productSku],
        queryFn: async () => {
            const variables = {sku: productSku};
            return request(getAutoRelatedSkus, variables);
        },
        enabled: productSku !== null,
        refetchOnWindowFocus: false,
    });

    const autoRelatedProductsQuery = useQuery({
        queryKey: ['autoRelatedProducts', autoRelatedSkus],
        queryFn: async () => {
            const variables = {skus: autoRelatedSkus};
            return request(getAutoRelatedProducts, variables);
        },
        enabled: autoRelatedSkus?.length > 0,
        refetchOnWindowFocus: false,
    });

    const productReviewsQuery = useQuery({
        queryKey: ['productReviews', productSku],
        queryFn: async () => {
            return request(getProductReviews, {
                sku: productSku,
                pageSize: PAGE_SIZE,
                currentPage: currentReviewPage
            });
        },
        enabled: !!productSku,
        refetchOnWindowFocus: false,
    });

    const productReviewRatingsQuery = useQuery({
        queryKey: ['reviewRatings'],
        queryFn: async () => {
            return request(productReviewRatingsMetadata);
        },
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setReviewRatings(data.data.productReviewRatingsMetadata.items);
        },
    });

    const mutation = useMutation((input: CreateProductReviewRequest) =>
        request(createProductReview, {input})
    );

    const handleAddReview = (review: any) => {
        mutation.mutate(review);
    }

    useEffect(() => {
        if (productAttributesQuery?.data) {
            setProductAttributes(productAttributesQuery?.data?.data?.products?.items[0]);
        }

        if (autoRelatedSkusQuery?.data) {
            const skus = autoRelatedSkusQuery?.data?.data?.getAutoRelatedSkus.map(product => product.sku);
            setAutoRelatedSkus(skus);
        }

        if (autoRelatedProductsQuery?.data) {
            setAutoRelatedProducts(autoRelatedProductsQuery?.data?.data?.products?.items);
        }

    }, [
        productAttributesQuery.isSuccess,
        autoRelatedSkusQuery.isSuccess,
        autoRelatedProductsQuery.isSuccess
    ]);

    useEffect(() => {
        if (productReviewRatingsQuery?.data) {
            setReviewRatings(productReviewRatingsQuery?.data?.data?.productReviewRatingsMetadata?.items);
        }

        if (productReviewsQuery.data) {
            const reviewsData = productReviewsQuery?.data?.data?.products?.items[0]?.reviews?.items || [];
            // ratings_breakdown = empty array
            const processedReviews = reviewsData.map(review => ({
                ...review,
                average_rating: review.average_rating || 100,
                ratings_breakdown: review.ratings_breakdown.length > 0
                    ? review.ratings_breakdown
                    : [{name: "Calitate", value: "5"}]
            }));
            setProductReviews(processedReviews);
        }
    },[
        productReviewRatingsQuery.isSuccess,
        productReviewsQuery.isSuccess,
    ]);

    return (
        <ProductViewStateContext.Provider value={
            {
                productSku,
                productAttributes,
                description,
                customAttributes,
                autoRelatedProducts,
                handleAddReview,
                reviewRatings,
                neededRating,
                productReviews,
                config,
                loading: productAttributesQuery.isLoading || autoRelatedSkusQuery.isLoading || autoRelatedProductsQuery.isLoading ||productReviewRatingsQuery.isLoading || productReviewsQuery.isLoading,
                error: productAttributesQuery.error || autoRelatedSkusQuery.error || autoRelatedProductsQuery.error || productReviewRatingsQuery.error || productReviewsQuery.error,
            }
        }>
            {children}
        </ProductViewStateContext.Provider>
    );
}

export const useProductViewState = () => {
    const context = useContext(ProductViewStateContext);
    if (context === undefined) {
        throw new Error("useProductViewState must be used within a ProductViewProvider");
    }
    return context;
}
